const options = {
  admin: [
    {
      key: 'master-data',
      label: 'Master Data',
      leftIcon: 'ion-ios-folder-outline',
      children: [
        {
          key: 'pengda',
          label: 'Pengda'
        },
        {
          key: 'pengcab',
          label: 'Pengcab'
        },
        {
          key: 'dojang',
          label: 'Dojang'
        },
        {
          key: 'anggota',
          label: 'Anggota'
        },
        {
          key: 'users',
          label: 'User'
        },
        {
          key: 'geup',
          label: 'Geup'
        },
        {
          key: 'banner',
          label: 'Banner'
        }
      ]
    },
    {
      key: 'transaksi-ujian',
      label: 'Transaksi Ujian',
      leftIcon: 'ion-university',
      children: [
        {
          key: 'menunggu-biaya-kirim',
          label: 'Menunggu Biaya Kirim'
        },
        {
          key: 'menunggu-pembayaran',
          label: 'Menunggu Pembayaran'
        },
        {
          key: 'menunggu-konfirmasi',
          label: 'Menunggu Konfirmasi'
        },
        {
          key: 'cetak-sertifikat',
          label: 'Cetak Sertifikat'
        },
        {
          key: 'menunggu-pengiriman',
          label: 'Menunggu Pengiriman'
        },
        {
          key: 'history-transaksi',
          label: 'History Transaksi'
        }
      ]
    },
    {
      key: 'laporan',
      label: 'Laporan',
      leftIcon: 'ion-ios-pie-outline',
      children: [
        {
          key: 'cetaksertifikat',
          label: 'Cetak Sertifikat'
        },
        {
          key: 'cetaksertifikatcopy',
          label: 'Cetak Sertifikat Copy'
        },
        {
          key: 'pendapatan',
          label: 'Pendapatan'
        },
        {
          key: 'jumlahanggota',
          label: 'Jumlah Anggota'
        },
        {
          key: 'record-official',
          label: 'Record Poin'
        }
      ]
    },
    {
      key: 'event',
      label: 'Event',
      leftIcon: 'ion-android-calendar'
    },
    {
      key: 'record-official',
      label: 'Record Poin',
      leftIcon: 'ion-android-people',
      children: [
        {
          key: 'record-poin',
          label: 'Record Poin'
        },
        {
          key: 'record-poin/isi-poin',
          label: 'Isi Poin'
        }
      ]
    },
    {
      key: 'pengajuan-revisi',
      label: 'Pengajuan Revisi',
      leftIcon: 'ion-android-warning'
    }
  ],
  pengda: [
    {
      key: 'dojang',
      label: 'Dojang',
      leftIcon: 'ion-ios-home'
    },
    {
      key: 'anggota',
      label: 'Anggota',
      leftIcon: 'ion-ios-people'
    },
    {
      key: 'transaksi',
      label: 'Kenaikan Geup',
      leftIcon: 'ion-arrow-graph-up-right',
      children: [
        {
          key: 'pengajuan-transaksi',
          label: 'Pengajuan Transaksi'
        },
        {
          key: 'history-transaksi',
          label: 'History Transaksi'
        }
      ]
    },

    {
      key: 'event',
      label: 'Event',
      leftIcon: 'ion-android-calendar',
      children: [
        {
          key: 'list-event',
          label: 'List Event'
        },
        {
          key: 'transaksi-event',
          label: 'Transaksi Event'
        }
      ]
    }
  ],
  ketua_dojang: [
    {
      key: 'anggota',
      label: 'Anggota',
      leftIcon: 'ion-ios-people',
      children: [
        {
          key: 'anggota',
          label: 'Data Anggota'
        },
        {
          key: 'list-naik-geup-anggota',
          label: 'Naik Geup Anggota'
        }
      ]
    },
    {
      key: 'anggota-naik-geup',
      label: 'Naik Geup',
      leftIcon: 'ion-android-cart'
    },
    {
      key: 'transaksi',
      label: 'Transaksi Naik Geup',
      leftIcon: 'ion-arrow-graph-up-right',
      children: [
        {
          key: 'mengunggu-koreksi-admin',
          label: 'Menunggu Koreksi Admin'
        },
        {
          key: 'upload-pembayaran',
          label: 'Upload Pembayaran'
        },
        {
          key: 'menunggu-konfirmasi',
          label: 'Menunggu Konfirmasi'
        },
        {
          key: 'transaksi-selesai',
          label: 'Transaksi Selesai'
        }
      ]
    },
    {
      key: 'pengajuan-revisi',
      label: 'Pengajuan Revisi',
      leftIcon: 'ion-android-warning'
    },
    {
      key: 'event',
      label: 'Event',
      leftIcon: 'ion-android-calendar',
      children: [
        {
          key: 'list-event',
          label: 'List Event'
        },
        {
          key: 'transaksi-event',
          label: 'Transaksi Event'
        }
      ]
    },
    {
      key: 'profile-dojang',
      label: 'Profile Dojang',
      leftIcon: 'ion-android-contact'
    }
  ]
};

export default options;
