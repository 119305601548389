import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ErrorBoundary from './ErrorBoundary';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Home = lazy(() => import('./containers/Pages/Home'));

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.accessToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

function PublicRoute({ children, ...rest }) {
  return <Route {...rest} render={() => children} />;
}
PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PublicRoute path="">
              <Home />
            </PublicRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
