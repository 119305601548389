import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const instance = axios.create({ baseURL: REACT_APP_API_URL });

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response.data,
  ({ response }) => {
    return Promise.reject(response.data.message);
  }
);

const publicApi = {
  login: (data) => {
    return instance({
      method: 'post',
      url: '/login',
      data
    });
  },
  homeBanner: () => {
    return instance({
      method: 'get',
      url: '/home/banner'
    });
  },
  homeDojang: ({ id = 0, params = {} }) => {
    let query = new URLSearchParams(params);
    return instance({
      method: 'get',
      url: `/home/dojang/${id}${query.toString() ? '?' + query.toString() : ''}`
    });
  },
  homeOrganisasi: ({ params = {} }) => {
    let query = new URLSearchParams(params);
    return instance({
      method: 'get',
      url: `/home/organisasi/${query.toString() ? '?' + query.toString() : ''}`
    });
  },
  homeWasit: ({ id = 0, params = {} }) => {
    let query = new URLSearchParams(params);
    return instance({
      method: 'get',
      url: `/home/wasit/${id}${query.toString() ? '?' + query.toString() : ''}`
    });
  },
  homeCariAnggota: ({ id = 0, params = {} }) => {
    let query = new URLSearchParams(params);
    return instance({
      method: 'get',
      url: `/home/cari-anggota/${id}${query.toString() ? '?' + query.toString() : ''}`
    });
  },
  homeEvent: ({ id = 0 }) => {
    return instance({
      method: 'get',
      url: `/home/event/${id}`
    });
  },
  homeNoAnggota: ({ params = {} }) => {
    let query = new URLSearchParams(params);
    return instance({
      method: 'get',
      url: `/home/no-anggota/${query.toString() ? '?' + query.toString() : ''}`
    });
  },
  homeRegisterDojang: (id, data) => {
    return instance({
      method: 'post',
      url: `/home/register-dojang/${id}`,
      data
    });
  }
};

export default publicApi;
