import { notification } from 'antd';

const createNotification = (type, message, description, duration = 4.5) => {
  notification[type]({
    message,
    description,
    duration
  });
};
export default createNotification;
